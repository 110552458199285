/* .contact-icon {
  position: fixed;
  bottom: 20px;
  right: 20px;
  cursor: pointer;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.8); /* Adjust the background color to match your app's theme */
  /* border-radius: 50%;
  padding: 4px;
}

.contact-modal {
  position: fixed;
  z-index: 1001;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.contact-modal-content {
  background-color: #fefefe; /* Adjust the background color to match your app's theme */
  /* margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 500px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 8px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
  cursor: pointer;
}  */


.contact-icon {
  position: fixed !important;
  bottom: 20px !important;
  right: 20px !important;
  font-size: 2rem !important;
  z-index: 1000 !important;
  cursor: pointer !important;
}

.contact-modal {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.5) !important;
  z-index: 1001 !important;
}

.contact-modal-content {
  position: relative !important;
  background-color: #fff !important;
  padding: 2rem !important;
  border-radius: 4px !important;
  width: 100% !important;
  max-width: 500px !important;
}

.close {
  position: absolute !important;
  top: 5px !important;
  right: 15px !important;
  font-size: 1.5rem !important;
  cursor: pointer !important;
}

.close:hover {
  color: black !important;
}

.contact-form {
  display: flex !important;
  flex-direction: column !important;
}

.contact-form label {
  margin-top: 1rem !important;
  font-weight: 500 !important;
}

.contact-form input,
.contact-form select,
.contact-form textarea {
  margin-top: 0.5rem !important;
  padding: 0.5rem !important;
  border: 1px solid #ccc !important;
  border-radius: 4px !important;
}

.contact-form button {
  margin-top: 1rem !important;
  padding: 0.5rem !important;
  border: none;
  border-radius: 4px !important;
  background-color: #E9D5A1; /* Adjust the background color to match your app's theme */
  color: #fff;
  font-weight: bold !important;
  cursor: pointer !important;
}

.contact-form button:hover {
  /* background-color: #CDBB90 !important; Adjust the hover color to match your app's theme */
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.contact-form input[type='email'],
.contact-form input[type='url'] {
  width: 100% !important;
}

.send-btn {
  background-color: #E9D5A1 !important;
  border-color: #E9D5A1 !important;
  display: block !important;
  margin-right: 5px !important;
  position: relative !important;
  width: 50% !important;
  color: #000 !important;
  position: relative !important;
}

.cancel-btn {
  background-color: black !important;
  border-color: black !important;
  display: block !important;
  width: 50% !important;
}

.loader {
  display: inline-block;
  border: 2px solid #f3f3f3;
  border-top: 2px solid #000;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: spin 2s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.contact-icon-pulse {
animation: pulse 1.5s infinite;
}

@keyframes pulse {
0% {
transform: scale(1);
opacity: 1;
}
50% {
transform: scale(1.1);
opacity: 0.7;
}
100% {
transform: scale(1);
opacity: 1;
}
}

.say-hello {
  position: absolute;
  top: 50%;
  left: -60px;
  transform: translateY(-50%);
  font-size: 1.5rem;
  font-weight: bold;
}

.contact-icon-container {
  position: relative;
}


@keyframes marquee {
0% { transform: translateX(100%); }
100% { transform: translateX(-100%); }
}

.formButtonGroup {
    display: flex !important;
    flex-direction: horizontal !important;
    width: 100% !important;
    margin: 0 auto !important;
}
.contactHeading {
    text-align: center;
    font-weight: bold;
}

.contact-para {
    text-align: center;
}